import "./App.css";
import Navigation from "./app/navigation/Navigation";

function App() {
  return (
    <div className>
          <Navigation />
    </div>
  );
}

export default App;

