import { useRef, useState } from "react";
import { useEffect } from "react";
import {
  biopac,
  bite,
  dc,
  ez,
  jio,
  NCFIT,
  popupPlay,
  producelocatore,
  ptc,
  shopify,
  shopify1,
  toltet,
  webPortfolio,
} from "../assets";
import AOS from "aos";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";

const Page = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      AOS.init();
    }, 500);
  }, []);
  return (
    <>
      <div className="">
        <div className="page-web">
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                {" "}
                <div
                  data-aos="zoom-in"
                  data-aos-duration="1500"
                  className="w-set-top"
                >
                  <h1 className="">TOLTEC LIGHTING</h1>
                  <p className="">
                    {" "}
                    Toltec Lighting is a company that specializes in the design
                    and manufacture of high-quality, decorative lighting
                    fixtures. Their products are known for their unique designs,
                    fine craftsmanship, and a wide variety of styles, including
                    traditional, contemporary, and transitional. Toltec Lighting
                    offers a diverse range of lighting solutions such as
                    chandeliers, pendants, wall sconces, bath lights, and
                    ceiling lights. The company's fixtures often feature
                    intricate details, hand-painted finishes, and are made from
                    materials such as glass, metal, and fabric. Toltec Lighting
                    aims to provide aesthetically pleasing and functional
                    lighting options for residential and commercial spaces.
                  </p>
                  <span className="">Tech Stack: Wordpress</span>
                </div>
              </div>
              <div className="col-md-7">
                <div className="mt-5">
                  <div className="col-md-12 col-12">
                    <div className="text-center">
                      <img
                        src={toltet}
                        className="biopac-img w-100"
                        data-aos="flip-left"
                        data-aos-easing="ease-out-cubic"
                        data-aos-duration="1500"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-web">
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                {" "}
                <div
                  data-aos="zoom-in"
                  data-aos-duration="1500"
                  className="w-sets"
                >
                  <h1 className="">PTC LABORATORIES</h1>
                  <p className="">
                    {" "}
                    PTC Labs offers DNA testing services for law enforcement and
                    legal professionals to aid in criminal investigations and
                    legal proceedings. This includes DNA profiling, paternity
                    testing, and identification of human remains.
                  </p>
                  <span className="">Tech Stack: Shopify</span>
                </div>
              </div>
              <div className="col-md-7">
                <div className="mt-5">
                  <div className="col-md-12 col-12">
                    <div className="text-center">
                      <img
                        src={ptc}
                        className="biopac-img w-100"
                        data-aos="flip-left"
                        data-aos-easing="ease-out-cubic"
                        data-aos-duration="1500"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-web">
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                {" "}
                <div
                  data-aos="zoom-in"
                  data-aos-duration="1500"
                  className="w-sets"
                >
                  <h1 className="">BEAUTOLOGIE</h1>
                  <p className="">
                    {" "}
                    Beautologie is the widely known as the top plastic surgery
                    center in Bakersfield, Fresno, Stockton and Newport Beach
                    California. Cosmetic Surgery procedures offered at
                    Beautologie include Breast Augmentation, Tummy Tuck,
                    Liposuction and Mommy Makeovers. We also have a giant
                    Medical Aesthetics practice performing Botox™, Restylane,
                    Fillers, Injectables, Lasers and more.
                  </p>
                  <span className="">Tech Stack: Shopify</span>
                </div>
              </div>
              <div className="col-md-7">
                <div className="mt-5">
                  <div className="col-md-12 col-12">
                    <div className="text-center">
                      <img
                        src={shopify1}
                        className="biopac-img w-100"
                        data-aos="flip-left"
                        data-aos-easing="ease-out-cubic"
                        data-aos-duration="1500"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-web">
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                {" "}
                <div
                  data-aos="zoom-in"
                  data-aos-duration="1500"
                  className="w-sets"
                >
                  <h1 className="">BIOPAC</h1>
                  <p className="text-white">
                    {" "}
                    Biopac is a comprehensive web-based SAAS platform designed
                    for researchers to manage their studies and data
                    efficiently. It provides features such as account management
                    for different user roles (Admins, Managers, Researchers),
                    Two-Factor Authentication (2FA) for enhanced security, and
                    robust file management capabilities supporting various
                    formats. The platform allows users to manage research
                    studies, user groups, and metadata, track data storage and
                    costs, and handle inventory and shipping of physical
                    devices, ensuring a streamlined and secure research
                    environment.
                  </p>
                  <span className="">Tech Stack: Next Js</span>
                </div>
              </div>
              <div className="col-md-7">
                <div className="mt-5">
                  <div className="col-md-12 col-12">
                    <div className="text-center">
                      <img
                        src={biopac}
                        className="biopac-img w-100"
                        data-aos="flip-left"
                        data-aos-easing="ease-out-cubic"
                        data-aos-duration="1500"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-web">
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                {" "}
                <div
                  data-aos="zoom-in"
                  data-aos-duration="1500"
                  className="w-sets"
                >
                  <h1 className="text-dark">DC DMV</h1>
                  <p className="text-dark">
                    {" "}
                    The driver's educational website transforms the traditional
                    driver's manual into 13 easy-to-understand chapters, each
                    designed to make learning traffic rules simple and engaging.
                    Each lesson is followed by multiple-choice quizzes to
                    reinforce your understanding and ensure you're retaining the
                    key information. Choose a lesson, learn at your own pace,
                    and take the quizzes to become a confident and knowledgeable
                    driver. Our structured approach ensures that you not only
                    prepare thoroughly for your driving test but also build a
                    solid foundation for safe driving practices.
                  </p>
                  <span className="text-dark">Tech Stack: Wordpress</span>
                </div>
              </div>
              <div className="col-md-7">
                <div className="mt-5">
                  <div className="col-md-12 col-12">
                    <div className="text-center">
                      <img
                        src={dc}
                        className="biopac-img w-100"
                        data-aos="flip-left"
                        data-aos-easing="ease-out-cubic"
                        data-aos-duration="1500"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-web">
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                {" "}
                <div
                  data-aos="zoom-in"
                  data-aos-duration="1500"
                  className="w-sets"
                >
                  <h1 className="text-dark">NCFIT WEB</h1>
                  <p className="text-dark">
                    {" "}
                    The NCFIT Workout App admin panel is a robust and intuitive
                    interface designed to provide administrators with full
                    control over the app’s operations and user experience. With
                    features ranging from user management to detailed analytics,
                    the admin panel ensures that administrators can effectively
                    manage and optimize the app to meet the needs of its users.
                  </p>
                  <span className="text-dark">Tech Stack: React Js</span>
                </div>
              </div>
              <div className="col-md-7">
                <div className="mt-5">
                  <div className="col-md-12 col-12">
                    <div className="text-center">
                      <img
                        src={NCFIT}
                        className="biopac-img w-100"
                        data-aos="flip-left"
                        data-aos-easing="ease-out-cubic"
                        data-aos-duration="1500"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-web">
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                {" "}
                <div
                  data-aos="zoom-in"
                  data-aos-duration="1500"
                  className="w-sets"
                >
                  <h1 className="">PRODUCE LOCATOR</h1>
                  <p className="">
                    {" "}
                    The Produce Locator website serves as an online platform
                    connecting vendors, consumers, and grocers, offering a
                    seamless shopping experience for all parties involved. It
                    combines the convenience of e-commerce with the variety and
                    freshness of a traditional grocery store. The website is
                    designed with user-friendly interfaces, efficient search
                    functionalities, and secure payment gateways to ensure a
                    smooth transaction process.
                  </p>
                  <span className="">Tech Stack: React Js</span>
                </div>
              </div>
              <div className="col-md-7">
                <div className="mt-5">
                  <div className="col-md-12 col-12">
                    <div className="text-center">
                      <img
                        src={producelocatore}
                        className="biopac-img w-100"
                        data-aos="flip-left"
                        data-aos-easing="ease-out-cubic"
                        data-aos-duration="1500"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-web">
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                {" "}
                <div
                  data-aos="zoom-in"
                  data-aos-duration="1500"
                  className="w-sets"
                >
                  <h1 className="">FLEXILIGHTPRO</h1>
                  <p className="">
                    {" "}
                    LEXILIGHTPRO professional lightening system includes dual,
                    flexible arms with a telescopic pole for easy transportation
                    and adjustability. The base is extra sturdy compared to
                    other lamps on the market.
                  </p>
                  <span className="">Tech Stack: Shopify</span>
                </div>
              </div>
              <div className="col-md-7">
                <div className="mt-5">
                  <div className="col-md-12 col-12">
                    <div className="text-center">
                      <img
                        src={shopify}
                        className="biopac-img w-100"
                        data-aos="flip-left"
                        data-aos-easing="ease-out-cubic"
                        data-aos-duration="1500"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-web">
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                {" "}
                <div
                  data-aos="zoom-in"
                  data-aos-duration="1500"
                  className="w-sets"
                >
                  <h1 className="">PREFUSION WEB</h1>
                  <p className="">
                    {" "}
                    Prefusion Web is an innovative mobile application designed
                    to enhance your social networking experience. It leverages
                    advanced technologies and cutting-edge features to provide a
                    seamless and interactive platform for connecting with
                    friends, family, and like-minded individuals.
                  </p>
                  <span className="">Tech Stack: Php Yii</span>
                </div>
              </div>
              <div className="col-md-7">
                <div className="mt-5">
                  <div className="col-md-12 col-12">
                    <div className="text-center">
                      <img
                        src={webPortfolio}
                        className="biopac-img w-100"
                        data-aos="flip-left"
                        data-aos-easing="ease-out-cubic"
                        data-aos-duration="1500"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-web">
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                {" "}
                <div
                  data-aos="zoom-in"
                  data-aos-duration="1500"
                  className="w-sets"
                >
                  <h1 className="">POPUP PLAYE</h1>
                  <p className="">
                    {" "}
                    A Popup Player is a web-based media player that can be
                    launched in a separate, smaller window (popup) from the main
                    browser window. This functionality allows users to continue
                    browsing or working on other tasks while keeping the media
                    player visible and accessible.
                  </p>
                  <span className="">Tech Stack: Wordpress</span>
                </div>
              </div>
              <div className="col-md-7">
                <div className="mt-5">
                  <div className="col-md-12 col-12">
                    <div className="text-center">
                      <img
                        src={popupPlay}
                        className="biopac-img w-100"
                        data-aos="flip-left"
                        data-aos-easing="ease-out-cubic"
                        data-aos-duration="1500"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-web">
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                {" "}
                <div
                  data-aos="zoom-in"
                  data-aos-duration="1500"
                  className="w-sets"
                >
                  <h1 className="">BITE</h1>
                  <p className="">
                    {" "}
                    Bite is a modern, user-friendly website dedicated to food
                    enthusiasts, offering a unique platform to discover, share,
                    and enjoy a wide variety of recipes from around the world.
                    Whether you're a professional chef, a home cook, or simply
                    someone who loves to explore new flavors, Bite provides a
                    comprehensive resource for all your culinary needs.
                  </p>
                  <span className="">Tech Stack: React Js</span>
                </div>
              </div>
              <div className="col-md-7">
                <div className="mt-5">
                  <div className="col-md-12 col-12">
                    <div className="text-center">
                      <img
                        src={bite}
                        className="biopac-img w-100"
                        data-aos="flip-left"
                        data-aos-easing="ease-out-cubic"
                        data-aos-duration="1500"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-web">
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                {" "}
                <div
                  data-aos="zoom-in"
                  data-aos-duration="1500"
                  className="w-sets"
                >
                  <h1 className="">EZEVV</h1>
                  <p className="">
                    {" "}
                    EZEVV (Easy Electric Vehicle Ventures) is a pioneering
                    website dedicated to promoting and simplifying the adoption
                    of electric vehicles (EVs). The platform provides
                    comprehensive resources, tools, and community support for
                    prospective and current EV owners, helping them navigate the
                    transition to electric mobility with ease.
                  </p>
                  <span className="">Tech Stack: React Js</span>
                </div>
              </div>
              <div className="col-md-7">
                <div className="mt-5">
                  <div className="col-md-12 col-12">
                    <div className="text-center">
                      <img
                        src={ez}
                        className="biopac-img w-100"
                        data-aos="flip-left"
                        data-aos-easing="ease-out-cubic"
                        data-aos-duration="1500"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-web">
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                {" "}
                <div
                  data-aos="zoom-in"
                  data-aos-duration="1500"
                  className="w-sets"
                >
                  <h1 className="">GEOFENCING</h1>
                  <p className="">
                    {" "}
                    At Geofencing Solutions, we specialize in providing
                    cutting-edge location-based services that enable businesses
                    to engage with their customers in innovative and
                    personalized ways. Our geofencing technology creates virtual
                    perimeters around real-world locations, allowing you to
                    deliver targeted messages, offers, and notifications when
                    your audience enters or exits these defined areas.
                  </p>
                  <span className="">Tech Stack: React Js</span>
                </div>
              </div>
              <div className="col-md-7">
                <div className="mt-5">
                  <div className="col-md-12 col-12">
                    <div className="text-center">
                      <img
                        src={jio}
                        className="biopac-img w-100"
                        data-aos="flip-left"
                        data-aos-easing="ease-out-cubic"
                        data-aos-duration="1500"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Page;
